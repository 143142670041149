
#lp-testemonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 10%;
}

#lp-testemonials .testemonials-wrapper {
    margin-top: 2rem;
}

#lp-testemonials .testemonials-wrapper > div {
    padding: 2rem;
    box-shadow: 10px 10px 20px -6px rgba(0,0,0,0.1),-10px -10px 20px -6px rgba(0,0,0,0.1);
    margin-bottom: 2rem;
}

#lp-testemonials .testemonials-wrapper > div div {
     display: flex; justify-content: start; align-items: center;
     margin-top: 1rem;
}

#lp-testemonials .testemonials-wrapper > div img {
    width: 45px; height: 45px;
    border-radius: 50%;
    margin-right: 1rem;
}

#lp-testemonials .testemonials-wrapper > div h3 {
    font-weight: 500;
    color: var(--secondary);
}

@media screen and (max-width: 768px) {
    #lp-testemonials .testemonials-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }
}

@media screen and (max-width: 480px) {
    #lp-testemonials .testemonials-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }
}