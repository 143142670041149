
#btn-wrapper a {
    font-weight: 600; font-size: 1rem;
    transition: all 0.5s ease;
    text-transform: uppercase;
    color: var(--secondary);
    text-align: center;
    
}

#btn-wrapper a.primary {
    background: var(--secondary);
    color: var(--light-green);
    border: none;
}

#btn-wrapper a.primary:hover {
    color: var(--light-green);
    background: var(--gray);
}


