#section-title h3 {
    font-weight: 800;
    font-size: 2.5rem;
    color: #1d3557;
}

@media screen and (max-width: 768px) {
    #section-title h3 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 480px) {
    #section-title h3 {
        font-size: 1.7rem;
    }
}