

#lp-topbar {
    -webkit-box-shadow: -1px 4px 18px -8px #000000; 
    box-shadow: -1px 4px 18px -8px #000000;
    background: var(--primary);
    width: 100%; height: 60px;
    position: relative; top: 0; left: 0;
    display: flex; justify-content: space-between; align-items: center;
    padding: 0 10%;
}

#lp-topbar .action-btns {
    display: flex;
    flex-direction: row;
    
}

#lp-topbar .action-btns a {
    display: block;
    padding: 8px 14px; margin-left: 0.4rem;
    border-radius: 20px;
    font-weight: 600; font-size: 0.8rem;
    transition: all 0.5s ease;
    text-transform: uppercase;
    color: var(--secondary);
}

#lp-topbar .action-btns a.primary {
    background: var(--secondary);
    color: var(--light-green);
    border: none;
}

#lp-topbar .action-btns a.primary:hover {
    color: var(--light-green);
    background: var(--gray);
}

#lp-topbar .action-btns a.menu {
    height: 40px; width: 40px;
    display: flex; justify-content: center; align-items: center;
}

#lp-topbar .action-btns a.menu:hover {
    background-color: #000000;
}

#lp-topbar .action-btns a.menu span {
    color: var(--secondary);
    font-weight: lighter;
    font-size: 2rem;
}

#lp-topbar .nav {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;
    height: 100%;
}

#lp-topbar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 3rem;
    height: 100%;
}

#lp-topbar ul li,
#lp-topbar ul li a {
    height: 100%;
    transition: all 0.5s ease;
}

#lp-topbar ul li a {
    padding: 8px 14px;
    font-weight: 800;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    color: var(--secondary);
    display: flex; justify-content: center; align-items: center;
}

#lp-topbar ul li.active,
#lp-topbar ul li:hover {
    background: var(--light-green)
}

#lp-topbar .menu-btn-wrapper,
#lp-topbar .nav .close-menu-btn-wrapper {
    display: none;
    width: 30px; height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s ease;
}

#lp-topbar .menu-btn-wrapper a,
#lp-topbar .nav .close-menu-btn-wrapper a {
    display: flex; justify-content: center; align-items: center;
    width: 30px; height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s ease;
}

#lp-topbar .menu-btn-wrapper a span,
#lp-topbar .nav .close-menu-btn-wrapper a span {
    color: var(--secondary);
}

#lp-topbar .menu-btn-wrapper a:hover,
#lp-topbar .nav .close-menu-btn-wrapper a:hover {
    background-color: var(--gray);
}

@media screen and (max-width: 1024px) {
    #lp-topbar .logo {
        font-size: 1.5rem;
    }

    #lp-topbar .nav {
        display: flex; flex-direction: row; justify-content: space-around; align-items: center;
        position: fixed; top: -1000px; left: 0;
        width: 100%; height: 150px;
        padding: 2rem 0;
        background: var(--primary);
        transition: all 0.5s ease;
        -webkit-box-shadow: -1px 4px 18px -8px #000000; 
        box-shadow: -1px 4px 18px -8px #000000; 
    }

    #lp-topbar .nav .close-menu-btn-wrapper {
        position: absolute; bottom: 0; left: 0;
        display: flex; justify-content: center; align-items: center;
        width: 100%;
    }

    #lp-topbar .nav.show {
        top: 0;
    }

    #lp-topbar ul {
        flex-direction: column; justify-content: center; align-items: flex-start;
    }

    #lp-topbar ul li {
        height: 36px; width: 100%;
    }

    #lp-topbar ul li.active a,
    #lp-topbar ul li:hover {
        border: none;
    }

    #lp-topbar ul li.active,
    #lp-topbar ul li:hover {
        background: none;
    }

    #lp-topbar ul li:hover a {
        color: var(--light-green);
    }    

    #lp-topbar .action-btns {
        flex-direction: row; justify-content: center; align-items: center;
    }

    #lp-topbar .menu-btn-wrapper {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    #lp-topbar .action-btns {
        flex-direction: column-reverse;
    }
}