
#ultima-visualizacao {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
    cursor: pointer;
    justify-content: center; align-items: center;
    -webkit-box-shadow: -1px 4px 18px -8px #000;
    box-shadow: -1px 4px 18px -8px #000;
    user-select: none;
}

#ultima-visualizacao::after {
    content: "";
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    background: var(--secondary); 
    opacity: 0.9;
    z-index: 1;
    transition: all 0.5s ease;
}

#ultima-visualizacao:hover::after {
    opacity: 1;
}

#ultima-visualizacao:hover > div.content span {
    transform: scale(1.2);
}

#ultima-visualizacao:hover > div.content .action-description {
    opacity: 0;
}

#ultima-visualizacao div.content {
    position: relative;
    width: 100%; height: 100%;
    justify-content: center; align-items: center; gap: 0.5rem;
    padding-top: 2rem;
}

#ultima-visualizacao > div.content span {
    font-size: 6rem; font-weight: 300;
    color: #bababa;
    transition: all 0.5s ease;
}

#ultima-visualizacao div.content .action-description {
    color: #c6c5c5;
    text-align: center; text-transform: uppercase;
    font-size: 0.7rem; font-weight: 600;
    opacity: 1;
    transition: all 0.5s ease;
}

#ultima-visualizacao div.content .description {
    position: absolute; top: 0; left: 0; width: 100%;
    padding: 0.5rem 0;
}

#ultima-visualizacao div.content .description h1 {
    color: #bababa;
    text-align: center;
    font-size: 1.2rem; font-weight: 600;
}

#ultima-visualizacao div.content .description p {
    color: #c6c5c5;
    text-align: center;
    font-size: 0.7rem; font-weight: 600;
}


#ultima-visualizacao div.content .description h1,
#ultima-visualizacao div.content .description p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
