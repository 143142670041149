

#icon-btn {
    width: 30px; height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s ease;
    user-select: none;
}

#icon-btn a {
    display: flex; justify-content: center; align-items: center;
    width: 30px; height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s ease;
}

#icon-btn a span {
    color: var(--secondary);
}

#icon-btn a:hover {
    background-color: #ccc;
}