
#admin-search-field {
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
    background-color: var(--white);
    width: 260px;
    padding: 6px 10px;
    user-select: none;
    overflow: hidden;
}

#admin-search-field span {
    position: absolute;
    top: 0; right: 0;
    font-weight: lighter;
    cursor: pointer;
    transition: all 0.5s ease;
    height: 100%; width: 10%;
    border-left: 1px solid #ccc;
    vertical-align: middle;
    line-height: normal;
    background-color: var(--background);
    border-radius: 5px;
}

#admin-search-field span:hover {
    font-weight: 200;
}

#admin-search-field input {
    width: 90%;
    border: none;
    outline: none;
}