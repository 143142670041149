
#capitulo-fotonovela-detalhes {
    display: inline-block;
    height: 100%;
    margin-right: 0.5rem;
    background-color: var(--background);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
}

#capitulo-fotonovela-detalhes .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--black);
    opacity: .6;
    transition: all 0.5s ease;
}

#capitulo-fotonovela-detalhes:has( > input:checked )  .bg {
    opacity: 0;
}

#capitulo-fotonovela-detalhes img {
    transition: all .5s ease;
}

#capitulo-fotonovela-detalhes:hover img {
    transform: scale(1.1);
}

#capitulo-fotonovela-detalhes input {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute; top: 0; left: 0;
    opacity: 0;
    cursor: pointer;
}