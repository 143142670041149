
#not-found-page {
    background-color: var(--white);
    height: 100vh;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    user-select: none;
}

#not-found-page p {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 20px;
    color: #363636;
}
