
#generos {
    gap: 0.2rem;
    justify-content: flex-start;
}

#generos li {
    padding: 0.1rem 0.8rem;
    border-radius: 30px;
    background: #f6c578;
    font-size: 0.8rem; font-weight: 500;
    text-transform: lowercase;
}

@media screen and (max-width: 480px) {
    #generos li {
        padding: 0 0.6rem;
        font-size: 0.7rem;
    }
}