#admin-main {
    z-index: 5;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch;
    height: 100%; width: 100%;
    
}

#admin-main-header {
    background: white;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1),
    -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
    padding: 0.8rem 2%;
    border-radius: 0.5rem;
}

#admin-main-header .menu-btn {
    height: 40px; width: 40px;
    border-radius: 50%;
    display: flex; justify-content: center; align-items: center;
    transition: all 0.5s ease;
    background: transparent;
    user-select: none;
}

#admin-main-header .menu-btn span {
    transition: all 0.5s ease;
}

#admin-main-header .menu-btn:hover {
    background: var(--background);
}

#admin-main-header .menu-btn:active span {
    transform: scale(0.8);
}


/* user profile */

#user-profile-name-menu {
    width: 140px;
}

#admin-main-header  .user-profile-name {
    align-items: flex-start;
    padding: 0 .5rem;
    transition: all .5s ease;
}


#admin-main-header  .user-profile-name > div {
    align-items: flex-start;
    margin-right: 0.5rem;
}

#admin-main-header  .user-profile-name > input[type=checkbox]:hover + div span,
#admin-main-header  .user-profile-name > input[type=checkbox]:checked + div span {
    color: #2f2f2f;
}

#admin-main-header  .user-profile-name .name {
    font-weight: bold;
}

#admin-main-header  .user-profile-name .job-role {
    font-size: 10px;
}

#user-profile-name-menu a {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    justify-content: flex-start; gap: 0.4rem;
    transition: all 0.5s ease;
}

#user-profile-name-menu span {
    font-size: 0.9rem;
    font-weight: 600;
}

#user-profile-name-menu a:hover {
    background: var(--background);
}

/*  */

#admin-main-pages {
    transition: all 0.2s ease;
    height: calc(100% - 62px);
}


/* notifications */

#admin-main-header #notifications {
    padding: 0 2px;
    display: grid;
    place-items: center;
}

#admin-main-header  #notifications > .notifications-menu {
    width: 300px;
}

#admin-main-header  #notifications > .notifications-menu ul {
    max-height: 350px;
    overflow: auto;
    display: flex; flex-direction: column; align-items: stretch;
}

#admin-main-header  #notifications > .notifications-menu h1 {
    padding: .5rem 5px;
    font-size: 1rem;
    color: var(--secondary);
    border-radius: 5px;
    margin-bottom: 4px;
    cursor: auto;
}

#admin-main-header  #notifications > .notifications-menu li {
    display: block;
    padding: .8rem 0;
    padding-left: 0.5rem;
    transition: all 0.2s ease;
    display: flex; flex-direction: column; align-items: stretch;
}

#admin-main-header  #notifications > .notifications-menu li {
    border-top: 1px solid #ccc;
}

#admin-main-header  #notifications > .notifications-menu li:hover{
    background-color: var(--background);
    padding-left: 0;
}

#admin-main-header  #notifications > .notifications-menu li h5 {
   color: var(--secondary);
}

#admin-main-header  #notifications > .notifications-menu li i {
    font-size: 10px;
    font-style: normal;
}

#admin-main-header  #notifications > .notifications-menu li i.type {
    color:  tomato;
}

#admin-main-header  #notifications > .notifications-menu li p {
    font-size: 12px;
}

#admin-main-header  #notifications > span:not(.pin) {
    transition: all .5s ease;
    position: relative;
}

#admin-main-header  #notifications > span.pin {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: tomato;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: all .5s ease;
    animation: anim-pin-notification 1s infinite;
}

#admin-main-header  #notifications:hover > span:not(.pin) {
    transform: scale(1.1);
}

#admin-main-header  #notifications:hover > span.pin {
    opacity: 0;
}



/* notifications & user-profile-name */

#admin-main-header #notifications,
#admin-main-header  .user-profile-name {
    background-color: var(--background);
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative;
    user-select: none;
    border-radius: 5px;
    height: 37px;
}

#admin-main-header  #notifications input:checked ~ div.notifications-menu,
#admin-main-header  .user-profile-name > input[type=checkbox]:checked ~ #user-profile-name-menu  {
    opacity: 1;
    top: 50px;
    pointer-events: inherit;  
}

#admin-main-header  #notifications > .notifications-menu,
#user-profile-name-menu {
    z-index: 100;
    position: absolute;
    top: 40px;
    opacity: 0;
    right: 0;
    border: 1px solid var(--background);
    padding: 0.5rem;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
    background: white;
    pointer-events: none;
    box-shadow: 5px 5px 5px -6px rgba(162, 162, 162, 0.1),
                -5px -5px 5px -6px rgba(162, 162, 162, 0.1);
    transition: all 0.2s ease;
    user-select: none;
   
}

#admin-main-header  .user-profile-name:has(input[type=checkbox]:checked),
#admin-main-header  #notifications:has(input[type=checkbox]:checked)
{
    background-color: #e8e8e8;
}

#admin-main-header  #notifications > input[type=checkbox],
#admin-main-header  .user-profile-name > input[type=checkbox] {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 1000;
    opacity: 0;
}


@media screen and (max-width: 600px) {
    #admin-main-header  .user-profile-name .name {
        font-size: 0.8rem;
    }
}

@keyframes anim-pin-notification {
    0% {
        background-color: #f9b850;
    }
    100% {
        background-color: tomato;
    }
}