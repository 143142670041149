#admin-main-pages .page {
    padding: 1rem 2% 0 2%;
    height: 100%;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch;
}

#admin-main-pages .page > h1 {
    font-weight: 800;
    font-size: 1.5rem;
    color: var(--secondary);
} 

#admin-main-pages .page > div {
    height: 100%;
    overflow: hidden;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; gap: .5rem;
}


#admin-main-pages .page > div > div:first-child {
    display: flex; justify-content: end; align-items: center; gap: .5rem;
}

#admin-main-pages .page > div > div:nth-child(2) {
    height: 100%;
}
