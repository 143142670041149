#lp-footer {
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch;
    padding: 2rem 10% 1rem 10%;
    background: #fca311;
    margin-top: 2rem;
}

#lp-footer > div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    height: 100%;
}

@media screen and (max-width: 480px) {

    #lp-footer > div {
        padding-bottom: 2rem;
    }

    #lp-footer > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }
}