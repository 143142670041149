#lp-planos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 10%;
    background-color: var(--white);
}

#lp-planos .planos-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
}

#lp-planos .planos-wrapper .plano {
    padding: 2rem 1rem 2rem 1rem;
    margin-left: 1rem;
    width:400px;
    box-shadow: 10px 10px 20px -6px rgba(0,0,0,0.1),-10px -10px 20px -6px rgba(0,0,0,0.1);
    min-height: 230px;
}

#lp-planos .planos-wrapper .plano:first-child {
    background-color: #f7bc5c;
}

#lp-planos .planos-wrapper .plano h3 {
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0 0 1rem 0;
    font-size: 1.2rem;
    color: var(--secondary);
}

#lp-planos .planos-wrapper .plano h4 {
    text-align: center;
    font-weight: lighter;
    text-transform: uppercase;
    padding: 0 0 1rem 0;
    font-size: 2.5rem;
    color: var(--secondary);
}

#lp-planos .planos-wrapper .plano p {
    color: var(--gray);
}

@media screen and (max-width: 768px) {
    #lp-planos .planos-wrapper {
        flex-direction: column;
        justify-content: space-around;
    }

    #lp-planos .planos-wrapper .plano {
        margin-bottom: 1rem;
        width: 100%
    }
}