#admin-user-profile-page {
    padding: 1rem 2% 0 2%;
    height: 100%;
    display: flex; flex-direction: column; align-items: stretch;
}

#admin-user-profile-page h1 {
    font-weight: 800;
    font-size: 1.5rem;
    color: var(--secondary);
    text-align: center;
}

#admin-user-profile-page > div {
    height: 100%;
    display: flex; align-items: stretch; gap: 1rem;
}

#admin-user-profile-page > div section {
    height: 100%;
    background-color: var(--white);
    overflow: hidden;
    border-radius: .5rem;
    padding: 2rem;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1),
                -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
}

#admin-user-profile-page > div section {
    display: flex; flex-direction: column; justify-content: start; align-items: center;
    width: 100%;
}


#admin-user-profile-page > div section span {
    font-size: 5rem;
    font-weight: 200;
}

#admin-user-profile-page > div section > div {
    gap: 5px;
}

#admin-user-profile-page > div section > div span {
    font-size: 20px;
    user-select: none;
    cursor: pointer;
}

#admin-user-profile-page > div section > div span:hover {
    text-decoration: underline;
}