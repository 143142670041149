
#subscricao {
    justify-content: space-around; align-items: flex-start; gap: 0.4rem;
    margin-bottom: .8rem;
    user-select: none;
    background: var(--background);
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

#subscricao section {
    border-bottom: 1px solid #d5d4d4;
    width: 100%;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    padding: 1rem;
    padding-bottom: 10px;
    background-color: #eee;
}

#subscricao section h1 {
    font-weight: bold;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    
}

#subscricao section > div {
    display: flex; justify-content: end; align-items: center; gap: 1rem;
}

#subscricao section > div div {
    display: flex; justify-content: end; align-items: center; gap: .3rem;
    cursor: pointer;
}

#subscricao section > div div span {
    transition: all 300ms ease;
}

#subscricao section > div div span:last-child {
    font-size: 12px;
}

#subscricao section > div div span:first-child {
    font-size: 1rem;
}

#subscricao section > div div:hover span {
    font-weight: 700;
}


#subscricao > div {
    width: 100%;
    padding: 1rem;
    padding-top: 10px;
}

#subscricao > div > div {
    width: 100%;
    padding-left: 0.6rem;
}

#subscricao > div > div span {
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    font-size: 1rem;
}

#subscricao > div > div span.red {
    color: tomato;
}

#subscricao > div > div span.green {
    color: green;
}

#subscricao > div > div span.gray {
    color: gray;
}

#subscricao > div > div span.orange {
    color: rgb(199, 129, 0);
}

#subscricao > div > div span:first-child {
    font-size: 0.8rem;
    font-weight: 300;
}

#subscricao > div > div span:last-child {
    font-size: 0.8rem;
    font-weight: 700;
}


@media screen and (max-width: 800px) {
    #subscricao > div {
        display: grid; grid-template-columns: 1fr 1fr;
    }

    #subscricao > div > div {
        margin-bottom: 0.5rem;
    }

    #subscricao section h1 {
        text-align: center;
    }

    #subscricao section {
        display: flex; flex-direction: column; justify-content: center; align-items: center;
        padding-bottom: 10px;
    }
}