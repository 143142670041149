#chapters {
    background-color: var(--white);
    border-collapse: collapse;
    height: 100%;
}

#chapters td,
#chapters th {
    text-align: left;
    border-bottom: 1px solid #eee;
    padding: 5px 10px;
}

#chapters th:first-child {
    width: 5px;
}

#chapters td:first-child {
    font-weight: 600;
}

#chapters th:not(:nth-child(2)) {
    width: 5px;
}

@media  screen and (max-width: 600px) {
    #chapters td,
    #chapters th {
        font-size: .8rem;
    }

}