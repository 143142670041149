
#admin-dashboard-page #dashboard-items {
    overflow-y: auto;
    padding: 1rem 0;
    height: 100%;
}

#admin-dashboard-page #dashboard-items {
    display: grid; grid-template-columns: repeat(4, 1fr); grid-template-rows: 1fr 3fr; gap: 1rem;
    width: 100%;
}

#admin-dashboard-page #dashboard-items > div {
    border-radius: 0.5rem;
    color: white;
    padding: 1rem;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1),
                -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
    user-select: none;
}

#admin-dashboard-page #dashboard-items > div .material-symbols-outlined {
    font-weight: lighter;
    font-size: 5rem;
    color: var(--secondary);
}

#admin-dashboard-page #dashboard-items > div h1 {
    color: var(--secondary);
}

#admin-dashboard-page #dashboard-items > div p {
    font-weight: 800;
    color: var(--secondary);
}

#admin-dashboard-page #dashboard-items .subescritores-cadastrados {
    
    background: #67cbfa;
    justify-content: space-between !important;
}

#admin-dashboard-page #dashboard-items .lancamentos {
    background: #f9b850;
}

#admin-dashboard-page #dashboard-items .subscricoes-pendentes {
    background: white;
}

#admin-dashboard-page #dashboard-items .subscricoes-expiradas {
    background: #dfdcdc;
   
}

#admin-dashboard-page #dashboard-items .subescritores-por-pais {
    grid-column: 1/4;
    background: white;
}

#admin-dashboard-page #dashboard-items .fotonovelas-mais-vistas {
    background: white;
}

#admin-dashboard-page #dashboard-items .fotonovelas-mais-vistas table {
    width: 100%;
}

#admin-dashboard-page #dashboard-items .fotonovelas-mais-vistas table th {
    text-align: left;
    padding: 0.5rem 0;
    border: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 800;
}

#admin-dashboard-page #dashboard-items .fotonovelas-mais-vistas table tbody tr td{
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 500;
}

@media screen and (max-width: 900px) {
    #admin-dashboard-page #dashboard-items {
        grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr 2fr 2fr;
    }

    #admin-dashboard-page #dashboard-items .subescritores-por-pais {
        grid-column: 1/3;
    }

    #admin-dashboard-page #dashboard-items .fotonovelas-mais-vistas {
        grid-column: 1/3;
    }
}

@media screen and (max-width: 768px) {
    
    #admin-dashboard-page #dashboard-items {
        grid-template-columns: 1fr; grid-template-rows: repeat(4, 1fr) 2fr;
    }

    #admin-dashboard-page #dashboard-items .subescritores-por-pais {
        grid-column: 1/2;
        display: none;
    }

    #admin-dashboard-page #dashboard-items .fotonovelas-mais-vistas {
        grid-column: 1/2;
    }
}