
#custom-image-picker {
    border-radius: 4px;
    padding: 5px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    background-color: #f4f4f4;
}

#custom-image-picker button {
    display: block;
    height: 100%;
    width: 100%;
    border: none;
    background: none;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    cursor: pointer;
}

#custom-image-picker button span {
    color: #454545;
}

#custom-image-picker button:hover span {
    color: var(--black);
}

#custom-image-picker button span:first-child {
    font-size: 2rem;
}