#admin-button {
    border: none;
    display: flex; justify-content:start; align-items: center; gap: 5px;
    padding: 5px 10px;
    padding-right: 15px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
}

#admin-button.disabled {
    pointer-events: none;
}

#admin-button span {
    color: var(--white);
    font-weight: lighter;
}

#admin-button span:first-child {
    font-weight: 200;
}

#admin-button.big {
    padding: 6px 18px;
}

#admin-button.big span {
    font-size: 1rem;
}

#admin-button.big span:first-child {
    font-size: 30px;
}

#admin-button.small {
    padding: 0 15px;
}

#admin-button.fullWidth {
    width: 100%;
}

#admin-button.fullWidth {
    justify-content: center;
}

/*  */

#admin-button.primary.create {
    background-color: #198754;
}

#admin-button.primary.create:hover {
    background-color: #198754e9;
}

/*  */

#admin-button.primary.delete {
    background-color: #dc3545;
}

#admin-button.primary.delete:hover {
    background-color: #dc3546e7;
} 

/*  */

#admin-button.primary.delete span,
#admin-button.primary.create span,
#admin-button.primary.update span {
    color: var(--background);
}

/*  */

#admin-button.primary.update {
    background-color: var(--secondary);
}

#admin-button.primary.update:hover {
    background-color: #1d4157;
}

/*  */

#admin-button.primary.cancel {
    border: 1px solid #ccc;
    background-color: #f3f3f3;
}

#admin-button.primary.cancel:hover {
    background-color: #f3f3f37a;
} 

#admin-button.primary.cancel span {
    color: var(--black);
}

#admin-button.primary.cancel span:first-child {
    overflow: hidden;
}

#admin-button.hide-icon span:first-child  {
    width: 0;
    overflow: hidden;
}

/*  */

#admin-button.delete,
#admin-button.create,
#admin-button.update,
#admin-button.cancel {
    background: none;
    gap: 0;
}

#admin-button.delete:hover,
#admin-button.create:hover,
#admin-button.update:hover,
#admin-button.cancel:hover {
    opacity: .95;
}

#admin-button.delete span {
    color: #dc3546e7;
}

#admin-button.create span {
    color: #198754e9;
}

#admin-button.update span {
    color: var(--secondary);
}

#admin-button.cancel span {
    color: var(--black);
}


