#lp-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 10%;
}

#lp-banner > div:first-child {
    height: 100%; width: 50%; 
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    padding: 1rem;
}

#lp-banner > div:last-child {
    height: 100%; width: 50%; 
    display: flex; justify-content: center; align-items: end;
    padding: 1rem;
}

#lp-banner img {
    width: 500px;
}

#lp-banner h1 {
    font-size: 3rem;
    color: #80ffdb;
    font-weight: 900;
    margin-bottom: 1rem;
    line-height: 1;
}

#lp-banner .action-btns {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
}

#lp-banner .action-btns a {
    display: block;
    padding: 8px 14px; margin-left: 0.4rem;
    border-radius: 20px;
    font-weight: 600; font-size: 1.2rem;
    transition: all 0.5s ease;
    text-transform: uppercase;
    color: var(--secondary)
}

#lp-banner .action-btns a.primary {
    background: var(--secondary);
    color: var(--light-green);
    border: none;
}

#lp-banner .action-btns a.primary:hover {
    color: var(--light-green);
    background: var(--gray);
}



/* @media screen and (max-width: 768px) { */
@media screen and (max-width: 1024px) {
    #lp-banner h1 {
        font-size: 2rem;
    }

    #lp-banner p {
        font-size: 0.9rem;
    }

    #lp-banner .action-btns a {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 768px) {
    #lp-banner .action-btns {
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }

    #lp-banner .action-btns a {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {
    #lp-banner .registrar-wrappper {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    #lp-banner {
        flex-direction: column;
        justify-content: start; align-items: center;
        padding: 0;
    }

    #lp-banner > div {
        padding: 0;
        width: 100%;
    }

    #lp-banner > div:first-child {
        justify-content: end;
        padding: 0;
        margin-top: 1rem;
        height: fit-content
    }

    #lp-banner > div:last-child {
        height: fit-content;
        padding: 0;
    }

    #lp-banner h1 {
        text-align: center;
    }

    #lp-banner .banner-details-text {
        display: none;
    }

    #lp-banner .registrar-wrappper {
        display: none;
    }

    #lp-banner img {
        width: 400px;
    }
}