#nav-client {
    position: absolute; top: 0; left: 0;
    width: 100%; height: 50px;
    padding: 0 5%;
    border-bottom: 1px solid #ccc;
    background-color: #eeeeee;
    overflow: hidden;
    -webkit-box-shadow: -1px 4px 18px -8px #676666;
    box-shadow: -1px 4px 18px -8px #676666;
    z-index: 10;
}

#nav-client > div:first-child {
    width: 250px;
    text-align: center;
}

#nav-client .icon-btns-wrapper {
    position: relative;
}

#nav-client .notification {
    position: relative;
    display: none;
}

#nav-client .notification > div.notification-pin {
    background: var(--primary);
    border-radius: 50%; border: 2px solid #ccc;
    overflow: hidden;
    justify-content: center;
    user-select: none;
    height: 0.8rem; width: 0.8rem;
    position: absolute; top: 2px; right: 3px;
    opacity: 1;
    transition: all 0.5s ease;
}

#nav-client .notification:hover > div.notification-pin {
    opacity: 0;
}

#nav-client .close-and-menu-btn-wrapper {
    display: none;
}

#nav-client .nav {
    position: relative;
    height: 100%;
}

#nav-client .nav ul {
    height: 100%;
}

#nav-client .nav ul li {
    transition: all 0.5s ease-out;
    border-bottom: 2px solid transparent;
    height: 100%;
    user-select: none;
}

#nav-client .nav ul li:hover {
    background: #dfdfdf;
}

#nav-client .nav ul li a {
    padding: 0.5rem 0.7rem;
    justify-content: space-around;
    transition: all 0.5s ease-out;
    height: 100%;
}

#nav-client .nav ul li.active {
    background: #dfdfdf;
}

#nav-client .nav ul li a span:last-child {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 0.3rem;
    font-size: 1rem;
}

#nav-client .nav ul li a span {
    color: var(--gray);
    transition: all 0.5s ease;
}

@media screen and (max-width: 1024px) {
    #nav-client .nav ul li a span:last-child {
        font-size: 0.9rem;
    }

    #nav-client .nav ul li a span:first-child {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 800px) {
    #nav-client {
        justify-content: center;
        background: var(--primary);
        border: none;
        -webkit-box-shadow: -1px 4px 18px -8px #676666;
        box-shadow: -1px 4px 18px -8px #676666;
    }

    #nav-client > div:first-child {
        width: 100%;
    }

    #nav-client .nav {
        position: fixed; bottom: 0; left: 0;
        width: 100%; height: 50px;
        justify-content: stretch;
        border-top: 1px solid #ccc;
        background: #eeeeee;
    }

    #nav-client .nav > ul {
        margin: 0;
        width: 100%; height: 100%;
        justify-content:stretch;
    }

    #nav-client .nav > ul li {
        width: 100%;
    }

    #nav-client .icon-btns-wrapper {
        margin: 0;
        top: 0; right: 5%;
        flex-direction: row; justify-content: stretch; align-items: center;
    }

    #nav-client .nav ul li {
        margin: 0;
        transition: all 0.5s ease-out;
        border-bottom: 2px solid transparent;
    }

    #nav-client .nav ul li a {
        padding: 0.5rem 0;
        flex-direction: column; justify-content: space-around;
    }

    #nav-client .nav ul li:hover,
    #nav-client .nav ul li.active {
        background: #dfdfdf;
        border-bottom: 2px solid var(--secondary);
    }

    #nav-client .nav ul li:hover a {
        transform: none;
    }

    #nav-client .nav ul li a span {
        margin: 0
    }

    #nav-client .notification > div.notification-pin {
        background: var(--secondary);
    }

}

@media screen and (max-width: 480px) {
    #nav-client .nav ul li a span:first-child {
        font-weight: 300;
    }

    #nav-client .nav ul li a span:last-child {
        font-weight: 600;
        font-size: 0.8rem;
    }
}