#fotonovelas {
    height: 100%;
    overflow: hidden;
    display: flex; flex-direction: column; justify-content: space-between;
}

#fotonovelas .header {
    padding: 1rem 5%;
    background-color: var(--primary);
}

#fotonovelas .header h1 {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 400;
    user-select: none;
}

#fotonovelas .header small {
    font-size: 1rem;
}

#fotonovelas > .tab-content {
    padding: 0 5%;
    height: 100%;
    width: 100%;
    overflow-y: auto;overflow-x: hidden;
}

@media screen and (max-width: 480px) {
    #fotonovelas .header {
        background: #eee;
    }

    #fotonovelas .header h1 {
        font-size: 1.2rem;
    }

    #fotonovelas .header small {
        font-size: 0.9rem;
    }

    
}