#user-panel {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: var(--background);
    display: flex; justify-content: center; align-items: center;
    position: relative;
}

#user-panel .main {
    width: 100%;
    margin-top: 50px;
    height: calc(100% - 50px);
    overflow: hidden;
}


@media screen and (max-width: 800px) {

    #user-panel {
        justify-content: center; align-items: flex-start;
        height: calc(100vh - 50px);
    }

    #user-panel .main {
        margin-top: 50px;
        border: none;
    }
}