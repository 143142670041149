#fnbutton {
    padding: 6px 10px;
    border: none;
    font-weight: 600;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: flex; justify-content: center; align-items: center; gap: 2px;
    user-select: none;
    cursor: pointer;
    pointer-events: all;
}

#fnbutton:hover {
    opacity: 0.95;
}

#fnbutton span {
    font-size: 1.5rem;
}

#fnbutton.stretch  {
    width: 100%;
}

#fnbutton.icon-button  {
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 9px;
}

#fnbutton.icon-button > span  {    
    display: none;
}

#fnbutton.inactive {
    pointer-events: none;
}

#fnbutton.default {
    background-color: #eee;
    color: var(--black);
}

#fnbutton.primary {
    background-color: var(--secondary);
}

#fnbutton.primary,
#fnbutton.primary > span {
    color: #eee;
}

#fnbutton.small {
    font-size: .8rem;
}

#fnbutton.big {
    font-size: .9rem;
}

#fnbutton.default.danger,
#fnbutton.default.danger > span {
    color: #dc3546e7;
}

#fnbutton.default.okay,
#fnbutton.default.okay > span {
    color: #198754;
}
