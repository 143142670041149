
#client-alert-component {
    display: flex; align-items: center; gap: 5px;
    padding: 8px 10px;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1),
    -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
    /* transition: all 1s ease; */

    animation: anim 300ms ease;
}

#client-alert-component p {
    font-weight: 500;
}

#client-alert-component.error {
    background-color: rgb(253, 237, 237);
}

#client-alert-component.error.no-background {
    box-shadow: none;
    background: none;
    border: 1px solid rgb(211, 47, 47);
}

#client-alert-component.error span {
    color: rgb(211, 47, 47);
}

#client-alert-component.error p {
    color: rgb(211, 47, 47);
}
 
#client-alert-component.success {
    background-color: rgb(237, 247, 237);
}

#client-alert-component.success span {
    color: rgb(46, 125, 50);
}

#client-alert-component.success p {
    color:  rgb(46, 125, 50);
}

@keyframes anim {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0.8;
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}