#lp-statistic {
    display: flex; flex-direction: column; justify-content: space-around; align-items: center;
    padding: 2rem 10%;
}

#lp-statistic .statistic {
    display: flex;
    flex-direction: row;
}

#lp-statistic .statistic .statistic-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgb(209, 209, 209);
    padding: 1rem;
}

#lp-statistic .statistic .statistic-item:last-child {
    border: none;
}

#lp-statistic .statistic .statistic-item h3 {
    font-size: 3rem;
    color: var(--primary);
}

#lp-statistic .statistic .statistic-item p {
    font-size: 1.2rem;
    font-weight: 200;
    color: var(--secondary);
    text-align: center;
}

@media screen and (max-width: 480px) {
    #lp-statistic .statistic {
        flex-direction: column;
    }

    #lp-statistic .statistic .statistic-item {
      border: none;
    }

    #lp-statistic .statistic-item h3 {
        font-size: 1.7rem;
    }
    
}