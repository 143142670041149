#comment {
    border-radius: 5px;
    background-color: var(--white);
    padding: .9rem;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
    overflow: hidden;
    display: flex; flex-direction: column; align-items: stretch;
    width: 300px;
    height: 120px;
    
}

#comment h4 {
    color: var(--black);
    font-size: 1rem;
    font-weight: 600;
}

#comment p {
    height: 100%;
    overflow: hidden;
    font-weight: 500;
    font-size: 0.9rem;
}

@media  screen and (max-width: 600px) {
    #comment {
        margin-right: 0;
        width: 100%;
    }
}