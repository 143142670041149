
#user-panel #home #home-content {
    padding: 0.7rem 5%;
    overflow: hidden;
    height: 100%;
    display: flex; justify-content: space-between; align-items: start; gap: 1rem;
    overflow-y: auto;
}

#user-panel #home #home-content div.lancamentos {
    width: 70%;
}

#user-panel #home #home-content div.side-details {
    width: 30%;
    display: flex; flex-direction: column; justify-content: center; align-items: stretch;
    
}

#user-panel #home #home-content div.lancamentos .lancamentos-list {
    min-height: 100%;
    overflow: hidden;
    padding-left: 2px; 
    text-align: center;
    padding-right: 5px;
}

#user-panel #home #home-content .header {
    padding: 0.7rem 0;
    margin-bottom: 0.5rem;
    box-shadow: 0px 7px 2px -5px rgba(0,0,0,0.1);
    user-select: none;
}

#user-panel #home #home-content .header h1 {
    justify-content: flex-start; align-items: center;
}

#user-panel #home #home-content .header h1 span:last-child {
    text-transform: uppercase;
    font-size: 1rem; font-weight: 800;
    margin-left: 5px;
}

#user-panel #home #home-content div.side-details > div {
    width: 100%;
}

@media screen and (max-width: 800px) {
    
    #user-panel #home #home-content {
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
    #user-panel #home #home-content div.lancamentos {
        width: 100%;
        grid-row: 2/3;
    }
    
    #user-panel #home #home-content div.side-details {
        gap: 1rem;
        width: 100%;
        grid-row: 1/2;
    }

    @media screen and (max-width: 480px) {
        #user-panel #home #home-content .header h1 span:first-child {
            font-size: 1.2rem;
        }
        
        #user-panel #home #home-content .header h1 span:last-child {
            font-size: .9rem;
        }
    }
    
}

