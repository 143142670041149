#detalhe-fotonovela {
    height: calc(100vh - 50px);
    overflow: hidden;
    display: flex; flex-direction: column; justify-content: space-between; gap: 1rem;
    padding-bottom: 1rem;
}

#detalhe-fotonovela .MuiPaper-root {
    border-radius: 0 !important;
    box-shadow: none !important;
    border-radius: 0 0 5px 5px !important;
}

#detalhe-fotonovela .MuiPaper-root * {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    padding: 5px 0;
    text-align: left;
}

#detalhe-fotonovela > .title {
    padding: 1rem 5%;
    overflow: hidden;
    display: flex; align-items: center; gap: 5px;
    border: none;
    background-color: var(--primary);
}

#detalhe-fotonovela > .title h1 {
    text-transform: uppercase;
    font-weight: 800;
    color: var(--secondary);
    font-size: 1.4rem;
}

#detalhe-fotonovela > .title span {
    font-size: 1.1rem;
    user-select: none;
}

#detalhe-fotonovela > .details-wrapper {
    display: flex; justify-content: space-between; align-items: stretch; gap: .5rem;
    border: none;
    overflow: hidden;
    min-height: 200px;
    margin: 0 5%;
    padding-top: 1rem;
}
 
#detalhe-fotonovela > .details-wrapper  img {
    width: 140px;
    object-fit: cover;
    border: 1px solid #ccc;
    padding: 0.5rem;
    background-color: #eee;
    border-radius: 5px;
    pointer-events: none;
}

#detalhe-fotonovela > .details-wrapper .text {
    width: 100%;
    padding: 0 1rem;
    background: var(--white);
    border: 1px solid #ccc;
    overflow: hidden;
    display: grid;
    gap: .5rem;
    align-content: flex-start;
    grid-template-columns: 50% 20% 30%;
    border-radius: 5px;
}

#detalhe-fotonovela > .details-wrapper .text > div .title {
    font-size: 0.9rem;
    font-weight: 600;
}

#detalhe-fotonovela > .details-wrapper .text > div {
    overflow: hidden;
}

#detalhe-fotonovela > .details-wrapper .text > div:last-child {
    grid-column: 1/4;
    display: flex; gap: 5px;
    padding: 5px 0;
}

#detalhe-fotonovela > .details-wrapper .text > div:first-child {
    grid-column: 1/4;
}

#detalhe-fotonovela > .details-wrapper,
#detalhe-fotonovela > .chapters,
#detalhe-fotonovela > .comments {
    height: 100%;
}


#detalhe-fotonovela > .chapters {
    background-color:#fff;
}

#detalhe-fotonovela > .comments {
    background-color: #fff;
}

#detalhe-fotonovela > .chapters,
#detalhe-fotonovela > .comments {
    padding: 0;
    margin: 0 5%;
    overflow: hidden;
    border-radius: 5px;
    display: flex; flex-direction: column;
    overflow-y: auto;
}



#detalhe-fotonovela > .chapters h1,
#detalhe-fotonovela > .comments h1 {
    color: var(--gray);
    font-size: 1.1rem;
    padding: 3px 10px;
    font-size: 1rem;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid#ccc;
    font-weight: 600;
    background-color: #eee;
}

#detalhe-fotonovela > .comments > div {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding: .8rem;
}

#detalhe-fotonovela > .comments > div {
    background-color: #fff;
}

#detalhe-fotonovela > .comments > div {
    display: flex; align-items: stretch; gap: 0.9rem;
    border-radius: 5px;
}

@media  screen and (max-width: 800px) {
    #detalhe-fotonovela {
        height: calc(100vh - 100px);
    }

    #detalhe-fotonovela > .title {
        background-color: #eee;
        border-bottom: 1px solid #ccc;
    }
   
    #detalhe-fotonovela > .title h1 {
        font-size: 1.1rem;
    }

    #detalhe-fotonovela > .details-wrapper .text > div .title {
        font-size: .8rem;
        font-weight: 600;
    }
    
    #detalhe-fotonovela > .details-wrapper .text > div p {
        font-size: .9rem;
    }
   
    
}

@media  screen and (max-width: 600px) {

    #detalhe-fotonovela {
        overflow-y: auto;
        display: block;
    }

    #detalhe-fotonovela > .title h1 {
        font-size: 0.9rem;
    }

    #detalhe-fotonovela > .details-wrapper,
    #detalhe-fotonovela > .chapters,
    #detalhe-fotonovela > .comments {
        height: fit-content;
    }

    #detalhe-fotonovela > .details-wrapper {
        display: block;
        overflow: visible;
       
    }

    #detalhe-fotonovela > .details-wrapper  img {
        border: none;
        width: 100%;
        height: 100%;
        display: block;
        padding: 0;
        border-radius: 5px;
        margin-bottom: 1rem;
    }

    #detalhe-fotonovela > .details-wrapper .text {
        border: none;
        display: block;
        padding: 1rem;
    }
    
    #detalhe-fotonovela > .details-wrapper .text > div:not(:last-child) {
        margin-bottom: 10px;
    }


    #detalhe-fotonovela > .chapters,
    #detalhe-fotonovela > .comments {
        overflow: visible;
    }
   
    #detalhe-fotonovela > .details-wrapper,
    #detalhe-fotonovela > .chapters {
        margin-bottom: 1rem;
    }

    #detalhe-fotonovela > .comments > div {
        flex-direction: column;
    }
}
