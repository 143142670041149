#notifications-box {
    position: fixed; right: -300px; top: 0;
    width: 240px;
    max-height: 500px;
    margin-top: 55px;
    background: var(--background);
    box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.1);
    transition: all 300ms ease;
    z-index: 10;
    border-radius: 1rem;
    overflow: hidden;
}

#notifications-box.show {
    right: 6px;
}

#notifications-box .header {
    border-bottom: 1px solid #ccc;
    padding: 1rem 1rem;
    background: #eee;
}

#notifications-box .header h1 {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 700;
    color: var(--secondary);
    user-select: none;
}

#notifications-box .items a {
    width: 100%;
    justify-content: center; align-items: flex-start;
    user-select: none;
    border-bottom: 1px solid #ccc;
    padding: 0.4rem 1rem;
    transition: all 0.5s ease;
    overflow: hidden;
    font-size: 1rem;
}


#notifications-box .items a:last-child {
    border-bottom: none
}

#notifications-box .items a:hover {
    background: #eee;
}

#notifications-box .items a .title {
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    width: 100%;
}

#notifications-box .items a .date {
    color: var(--gray);
    font-size: 0.7rem;
    text-transform: uppercase;
}

@media screen and (max-width: 460px) {
    #notifications-box .header h1 {
        font-size: 1.1rem;
        font-weight: 600;
    }
    
    #notifications-box .items a  {
        font-size: 0.9rem;
    }
}