#user-panel #home {
    height: 100%;
    overflow: hidden ;
    display: flex; flex-direction: column; justify-content: space-between;
}

@media screen and (max-width: 800px) {
    #user-panel #home {
        height: 100%;
    }
}