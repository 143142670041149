#plano-detalhes-page {
    padding: 1rem 2% 0 2%;
    height: 100%;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; gap: 1rem;
}

#plano-detalhes-page > table {
    overflow-y: auto;
}

#plano-detalhes-page > div:last-child {
    height: 100%;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; gap: .5rem;
    overflow: hidden;
}

#plano-detalhes-page  .fotonovelas {
    height: 100%;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; gap: .5rem;
    overflow-y: auto;
}

#plano-detalhes-page  .fotonovelas > div {
    height: 100%;
}

#plano-detalhes-page  .details .content-wrapper {
    padding: 10px;
    width: 100%;
}

#plano-detalhes-page  .details > div {
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


#plano-detalhes-page  .details .content-wrapper > div:not(:last-child) {
    border-bottom: 1px solid var(--background);
    display: flex; flex-direction: column; align-items: stretch;
    padding: 5px 0;
}

#plano-detalhes-page .details .content-wrapper > div span:first-child {
    font-weight: 700;
    font-size: .9rem;
}




