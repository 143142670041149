
#alert-component {
    display: flex; align-items: center; gap: 5px;
    padding: 8px 10px;
    border-radius: 5px;
}

#alert-component p {
    font-weight: 500;
}

#alert-component.error {
    background-color: rgb(253, 237, 237);
}

#alert-component.error span {
    color: rgb(211, 47, 47);
}

#alert-component.error p {
    color: rgb(95, 33, 32);
}
 
#alert-component.success {
    background-color: rgb(237, 247, 237);
}

#alert-component.success span {
    color: rgb(46, 125, 50);
}

#alert-component.success p {
    color: rgb(30, 70, 32);
}