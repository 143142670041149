@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@1,900&display=swap');

:root {
    --black: #2b2d42;
    --white: #ffffff;
    --primary: #fca311;
    --secondary: #1d3557;
    --gray: #495057;
    --light-green: #80ffdb;
    --background: #f8f9fa;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    color: var(--black);
    box-sizing: border-box;
}

html, 
body,
#root,
.app {
    height: 100vh;
    width: 100vw;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    overflow-y: auto; overflow-x: hidden;
    line-height: 1.5;
    background: var(--background);
}

.flex-row {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;
}

.flex-column {
    display: flex; flex-direction: column; justify-content: space-between; align-items: center;
}


/* Scroll bar */
::-webkit-scrollbar { 
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
  }
