#register-client {
    height: 100vh; width: 100vw;
    overflow: hidden;
    background: var(--background);
    display: flex; justify-content: center; align-items: center;
}

#register-client .main {
    background: #fca311;
    padding: 3rem 2rem;
    width: 360px; height: 80%;
    box-shadow: -1px 4px 18px -8px #000000;
}

#register-client-success button,
#register-client button {
    display: block;
    padding: 8px 14px; margin-left: 0.4rem;
    border-radius: 20px;
    font-weight: 500; font-size: 0.8rem;
    transition: all 0.5s ease;
    text-transform: uppercase;
    color: var(--secondary);
    text-align: center;
    font-size: 1rem;
    user-select: none;
    width: 100%;
    cursor: pointer;
}

#register-client-success button.primary,
#register-client button.primary {
    background: var(--secondary);
    color: var(--light-green);
    border: none;
}

#register-client-success button.primary:hover,
#register-client button.primary:hover {
    color: var(--light-green);
    background: var(--gray);
}

@media screen and (max-width: 480px) {

    #register-client .main {
        width: 100%; height: 100%;
        box-shadow: none;
        padding-top: 4rem;
    }
}