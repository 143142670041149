
#lp-lancamentos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 10%;
}

#lp-lancamentos .lancamentos-wrapper {
    display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 1fr 1fr; justify-items: stretch; gap: 1.5rem;
    width: calc(220px * 3);
    margin-top: 2rem;
}

#lp-lancamentos .lancamentos-wrapper .lancamento {
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    cursor: pointer;
    box-shadow: 10px 10px 20px -6px rgba(0,0,0,0.1),-10px -10px 20px -6px rgba(0,0,0,0.1);
    position: relative;
    background: var(--background);
    overflow: hidden;
    transition: all 0.5s ease;
}

#lp-lancamentos .lancamentos-wrapper .lancamento::after{
    content: "";
    width: 100%; height: 100%;
    background: var(--secondary);
    position: absolute; top: 0; left: 0;
    opacity: 0;
    transition: all 0.5s ease;
}

#lp-lancamentos .lancamentos-wrapper .lancamento:hover::after {
   opacity: 0.6;
}

#lp-lancamentos .lancamentos-wrapper .lancamento:hover img {
    transform: scale(1.1);
}

#lp-lancamentos .lancamentos-wrapper .lancamento img {
    width: 220px;
    height: 300px;
    transition: all 0.5s ease;
}

#lp-lancamentos .lancamentos-wrapper .lancamento > div {
    padding: 1rem 0;
    width: 100%;
}

#lp-lancamentos .lancamentos-wrapper .lancamento h3 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.9rem;
    margin-left: 5px; margin-top: 5px;
    color: var(--secondary);
}

@media screen and (max-width: 768px) {
    #lp-lancamentos .lancamentos-wrapper {
        display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr 1fr; justify-items: stretch; gap: 1.5rem;
        width: calc(220px * 2);
        margin-top: 2rem;
    }
}

@media screen and (max-width: 480px) {
    #lp-lancamentos {
        background-color: var(--white);
    }

    #lp-lancamentos .lancamentos-wrapper {
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        height: 400px;
        width: 100%;
        display: block;
    }

    #lp-lancamentos .lancamentos-wrapper .lancamento {
        width: 220px;
        display: inline-block;
    }

    #lp-lancamentos .lancamentos-wrapper .lancamento:not(:last-child) {
        margin-right: 2rem;
    }
}