
#client-loading-page {
    display: flex; justify-content: center; align-items: start; gap: 5px;
    padding-top: 15rem;
    height: 100vh;
}

#client-loading-page img {
    width: 20px;
}
