
#subscricao-ativa {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
    justify-content: center; align-items: center;
    user-select: none;
    border: 1px solid #eee;
}
