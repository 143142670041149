#modal-wrapper {
    height: 100vh;
    background: var(--secondary);
    overflow: hidden;
}

#modal-wrapper #modal-header {
    position: relative;
    background: var(--secondary);
    padding: 0.7rem 5%;
    box-shadow: -1px 4px 10px -8px #232323;
}


#modal-wrapper #modal-header h1 {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #dbd9d9;
}

#modal-wrapper #modal-header h2 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #dbd9d9;
}

#modal-wrapper #modal-header h3 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: #dbd9d9;
}

#modal-wrapper #modal-content {
    padding: 0 5%;
    height: calc(100% - 95px);
    overflow-y: auto;
    user-select: none;
}

#modal-wrapper #modal-content > div.left,
#modal-wrapper #modal-content > div.right {
    height: 100%; width: 50px;
    z-index: 1000;
}
#modal-wrapper #modal-content > div.left,
#modal-wrapper #modal-content > div.right,
#modal-wrapper #modal-content > div.center {
    display: flex; justify-content: center; align-items: center;
}

#modal-wrapper #modal-content > div.center {
    width: calc(100% - 100px); height: 100%;
    padding: 0.5rem 0;
}


#modal-wrapper #modal-content > div.center > img {
    width: 450px;
    height: 100%;
    pointer-events: none;
    object-fit: contain;
}

#modal-wrapper #modal-content a.nav-btn {
    user-select: none;
    cursor: pointer;
}

#modal-wrapper #modal-content a.nav-btn.hidden {
    opacity: 0;
    pointer-events: none;
}

#modal-wrapper #modal-content a.nav-btn span {
    font-size: 4rem;
    color: #80ffdb;
    transition: all 0.5s ease;
    

}

#modal-wrapper #modal-content a.nav-btn:hover span {
    color: #74eac9;
}


@media screen and (max-width: 600px) {

    #modal-wrapper #modal-header h1 {
        font-size: 1.1rem;
    }
    
    #modal-wrapper #modal-header h2 {
        font-size: 0.9rem;
    }
    
    #modal-wrapper #modal-header h3 {
        font-size: 0.8rem;
    }

    #modal-wrapper #modal-content > div.center {
        width: 80%;
    }

    #modal-wrapper #modal-content > div.left,
    #modal-wrapper #modal-content > div.right {
        width: 5%
    }

    #modal-wrapper #modal-content > div.center > img {
        width: 300px;
    }


}