#flat-btn {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: #80ffdb 0px 3px 8px;
    background-color: var(--secondary);
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: grid;
    place-items: center;
    transition: all 300ms ease;
    user-select: none;
}

#flat-btn:hover {
    opacity: 0.9;
}

#flat-btn:hover span {
    transform: scale(1.1);
    
}

#flat-btn span {
    color: #80ffdb;
    font-weight: 300;
    font-size: 2rem;
    transition: all 300ms ease;
}
