
#admin-sidebar {
    height: calc(100vh - 100px); width: 260px;
    position: absolute; top: 90px; left: -300px;
    transition: all 0.2s ease;
    z-index: 10;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1),
                -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
    padding: 1rem;
    overflow-y: auto;
}

#admin-sidebar.visible {
    left: 1rem;
}

#admin-sidebar.visible + #admin-main #admin-main-pages {
    margin-left: 260px;
}

#admin-sidebar-header {
    padding: 1rem 0.5rem;
    display: flex; justify-content: center; align-items: center;
}

#admin-sidebar ul {
    padding-top: 2rem;
    border-top: 1px solid #ccc;
}

#admin-sidebar ul .group-title {
    margin-top: 1rem;
    display: block;
    font-size: 0.8rem;
    color: #7e7c7c;
}

#admin-sidebar ul li a {
    justify-content: flex-start; gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
}

#admin-sidebar ul li:hover a {
    background: var(--background);
}

#admin-sidebar ul li a span {
    color: var(--secondary);
}

#admin-sidebar ul li a span:last-child {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.8rem;
}

#admin-sidebar ul li.active a {
    background: #dfdcdc;
}

#admin-sidebar ul li.active a span {
    color: var(--secondary);
}