
.admin-data-table {
    background-color: var(--white);
    height: 100%;
    overflow-y: auto;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch;
    border-radius: 5px;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1),
                -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
    user-select: none;
    width: 100%;
}

.admin-data-table  table {
    border-collapse: collapse;
    width: 100%;
}

.admin-data-table  table th {
    text-align: left;
    padding: 7px 10px;
}

.admin-data-table table td {
    text-align: left;
    padding: 5px 10px;
}

.admin-data-table   table td {
    font-weight: 500;
}

.admin-data-table   table th:not(:last-child),
.admin-data-table  td:not(:last-child) {
    border-right: 1px solid #cccccc92;
}

.admin-data-table  table thead tr,
.admin-data-table   table tbody tr {
    border-bottom: 1px solid #cccccc92;
}

.admin-data-table   table tbody tr {
    transition: all .5s ease;
}

.admin-data-table   table tbody tr:hover {
    background-color: #f5f5f563
}

.admin-data-table   table tbody td.btns-operacao {
    display: flex;  align-items: center; gap: 5px;
}

.admin-data-table   table thead th.btns-operacao {
    width: 1px;
}
