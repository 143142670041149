
#admin-login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#admin-login-page > div:not(.app-logo-wrapper) {
    width: 360px;
    padding: 20px;
    background-color: var(--white);
    border-radius: 1rem;
    box-shadow: 10px 10px 5px -6px rgba(162, 162, 162, 0.1), -10px -10px 5px -6px rgba(162, 162, 162, 0.1);
}

