
#cena-fotonovela-detalhes {
    display: inline-block;
    height: 100%;
    margin-right: 0.5rem;
    background-color: var(--background);
    border-radius: 3px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    cursor: pointer;
}

#cena-fotonovela-detalhes .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--black);
    opacity: .6;
    transition: all 0.5s ease;
}

#cena-fotonovela-detalhes:hover .bg {
    opacity: 0;
}

#cena-fotonovela-detalhes img {
    transition: all .5s ease;
}

#cena-fotonovela-detalhes:hover img {
    transform: scale(1.1);
}
