

#admin-status-badge {
    padding: 2px 0;
    border-radius: 100px;
    display: flex; justify-content: center;
    width: fit-content;
    height: fit-content;
    border-width: 1px;
    border-style: solid;
}

#admin-status-badge span {
    letter-spacing: 1px;
    font-weight: 700;
    font-size: .6rem;
    text-transform: uppercase;
}

#admin-status-badge.red {
    background-color: #dc3545;
    border-color: #de2336;
}

#admin-status-badge.red span {
    color: #fff;
}

#admin-status-badge.red-light {
    background-color: #f9a8b0;
    border-color: #dd8a92;
}

#admin-status-badge.red-light span {
   color: #3b3b3b;
}

#admin-status-badge.gray {
    background-color: var(--background);
    border-color: #dedede;
}

#admin-status-badge.gray span {
   color: var(--black);
}

#admin-status-badge.green {
    background-color: #4eeda332;
    border-color: #30e99351;
}

#admin-status-badge.green span {
   color: var(--black);
}


