
#lancamento  {
    justify-content: space-around; align-items: flex-start; gap: 0.4rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all 0.5s ease;
    user-select: none;
    background: #eee;
    padding: 0.3rem;
    border: 1px solid #d5d4d4;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

#lancamento:hover {
    background: #d5d4d4;
}

#lancamento img {
    width: 70px; height: 100px;
    border: 1px solid #d5d4d4;
    pointer-events: none;
}

#lancamento > div {
    width: 100%;
    overflow: hidden;
    justify-content: flex-start; align-items: flex-start; gap: 0.3rem;
}

#lancamento .title {
    display: flex; align-items: center; gap: 2px;
}

#lancamento .title h1 {
    font-size: 0.9rem; font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    padding-top: 0.3rem;
}

#lancamento .title span {
    font-size: 1rem;
    padding-top: 0.3rem;
}

#lancamento .description span {
    font-size: 0.6rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
}

@media screen and (max-width: 460px) {
    #lancamento .title h1 {
        font-size: 0.7rem;
    }
}