#faturacao {
    height: 100%;
    overflow: hidden;
    display: flex; flex-direction: column; justify-content: space-between;
    position: relative;
}

#faturacao .header {
    padding: 1rem 5%;
    background-color: var(--primary);
}

#faturacao .header h1 {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 400;
    user-select: none;
}

#faturacao .header small {
    font-size: 1rem;
}

#faturacao > .tab-content #subscricoes {
    padding-top: 1rem;
}

#faturacao > .tab-content {
    padding: 0 5%;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width: 480px) {
    #faturacao .header {
        background: #eee;
    }

    #faturacao .header h1 {
        font-size: 1.2rem;
    }

    #faturacao .header small {
        font-size: 0.9rem;
    }

    
}