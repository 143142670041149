#perfil-box {
    position: fixed; right: -300px; top: 0;
    width: 200px;
    max-height: 500px;
    margin-top: 55px;
    background: var(--background);
    box-shadow: 0px 0px 18px -4px rgba(0,0,0,0.1);
    transition: all 300ms ease;
    border-radius: 1rem;
    overflow: hidden;
}

#perfil-box.show {
    right: 6px;
}

#perfil-box .header {
    border-bottom: 1px solid #ccc;
    padding: 1rem 1rem;
    background: #eee;
}

#perfil-box .header h1 {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 700;
    color: var(--secondary);
    user-select: none;
}

#perfil-box .header h3 {
    font-size: 0.9rem;
    text-align: center;
    font-weight: 400;
    margin-top: -5px;
    user-select: none;
}

#perfil-box .items {
    align-items: start;
}

#perfil-box .items a {
    width: 100%;
    justify-content: start;
    user-select: none;
    padding: 8px 1rem;
    transition: all 0.5s ease;
    text-decoration: none;
    font-size: 0.9rem;
}

#perfil-box .items a:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

#perfil-box .items a:hover {
    background: #eee;
}

#perfil-box .items span:last-child {
    margin-left: 4px;
    font-weight: 600;
}

#perfil-box .items a:hover span {
    color: var(--gray);
}

@media screen and (max-width: 460px) {
    #perfil-box .header h1 {
        font-size: 1.1rem;
        font-weight: 600;
    }
    
    #perfil-box .header h3 {
        font-size: 0.8rem;
    }

    #perfil-box .items a  {
        font-size: 0.9rem;
    }
}