#userprofile-modal-wrapper {
    height: 100vh;
    background: var(--background);
    overflow: hidden;
}

#userprofile-modal-wrapper #userprofile-modal-header {
    position: relative;
    background: var(--secondary);
    padding: 0.7rem 5%;
    box-shadow: -1px 4px 10px -8px #232323;
}


#userprofile-modal-wrapper #userprofile-modal-header h1 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--background);
}

#userprofile-modal-wrapper #userprofile-modal-content {
    padding: 0 5%;
    height: calc(100% - 95px);
    overflow: hidden;
}

#userprofile-modal-wrapper #userprofile-modal-content > div:first-child {
    display: flex; flex-direction: column; justify-content: center;
    align-items: center;
    padding: 1rem 0;
}

#userprofile-modal-wrapper #userprofile-modal-content > div:first-child span {
    font-size: 5rem;
}

@media screen and (max-width: 600px) {

    #userprofile-modal-wrapper #userprofile-modal-header h1 {
        font-size: 1.1rem;
    }

}