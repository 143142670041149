#fotonovela-detalhes-page {
    padding: 1rem 2% 0 2%;
    height: 100%;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; gap: 1rem;
}

#fotonovela-detalhes-page > div:last-child {
    height: 100%;
    display: flex; flex-direction: column; justify-content: space-between; align-items: stretch; gap: .5rem;
    overflow: hidden;
}

#fotonovela-detalhes-page  .details {
    display: flex; justify-content: start; align-items: stretch; gap: .5rem;
    height: calc(100% - 35% - 150px);
}


#fotonovela-detalhes-page  .details img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

#fotonovela-detalhes-page  .details .content-wrapper {
    padding: 10px;
    width: 100%;
    overflow-y: auto;
}

#fotonovela-detalhes-page  .details > div {
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

#fotonovela-detalhes-page  .details .img-wrapper {
    width: 160px;
    overflow: hidden;
}


#fotonovela-detalhes-page  .details .content-wrapper > div:not(:last-child) {
    border-bottom: 1px solid var(--background);
    display: flex; flex-direction: column; align-items: stretch;
    padding: 5px 0;
}

#fotonovela-detalhes-page .details .content-wrapper > div span:first-child {
    font-weight: 700;
    font-size: .9rem;
}

#fotonovela-detalhes-page  .capitulos,
#fotonovela-detalhes-page  .cenas {
    background-color: var(--white);
    padding: .5rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex; flex-direction: column;
}

#fotonovela-detalhes-page  .capitulos {
    height: calc(100% - 40% - 150px);
}

#fotonovela-detalhes-page  .capitulos > div {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    height: 100%;
    background-color: var(--white);
}

#fotonovela-detalhes-page  .cenas {
    height: calc(100% - 40% - 35%);
}

#fotonovela-detalhes-page  .cenas > div {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    height: 150px;
}

