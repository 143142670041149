
#user-panel #home #welcome {
    margin: 0;
}

#user-panel #home #welcome .wrapper {
    justify-content: space-between; align-items: center;
    height: fit-content;
    background: #fcb036;
    box-shadow: 0px 0px 15px -5px rgba(0,0,0,0.1);
    padding: 0.5rem 5%;
}

#user-panel #home #welcome .wrapper > div {
    width: 100%; height: 100%;
    overflow: hidden;
}

#user-panel #home #welcome .wrapper > div:first-child {
    width: 60%;
    justify-content: center; align-items: flex-start;
    user-select: none;
}

#user-panel #home #welcome .wrapper > div:first-child h1 {
    font-size: 3rem; font-weight: 400;
    color: var(--secondary);
}

#user-panel #home #welcome .wrapper > div:first-child p {
    font-size: 1rem; font-weight: 500;
    text-align: left;
    color: var(--gray);
    justify-content: center; align-items: center;
}

#user-panel #home #welcome .wrapper div.image-wrapper {
    position: relative;
    width: fit-content;
    display: flex; justify-content: center; align-items: center;
    -webkit-box-shadow: -1px 4px 18px -8px #000;
    box-shadow: -1px 4px 18px -8px #000;
}

#user-panel #home #welcome .wrapper div.image-wrapper img {
    width: 230px;
    pointer-events: none;
}

@media screen and (max-width: 1024px) {
    
    #user-panel #home #welcome .wrapper > div:first-child h1 {
        font-size: 2.5rem;
    }

    #user-panel #home #welcome .wrapper > div:first-child {
        width: 50%;
    }

    #user-panel #home #welcome .wrapper div.image-wrapper img {
        width: 200px;
    }

}

@media screen and (max-width: 800px) {
    
    #user-panel #home #welcome .wrapper {
        background: #dfdfdf;
        justify-content: center; align-items: center;
        padding: 0.4rem 1rem;
    }
    
    #user-panel #home #welcome .wrapper > div:first-child h1 {
        font-size: 2.5rem;
        color: var(--gray);
        font-size: 2.4rem;
    }

    #user-panel #home #welcome .wrapper > div:first-child p {
        font-size: 0.95rem;
    }


}



@media screen and (max-width: 600px) {

    #user-panel #home #welcome .wrapper {
        padding: 1.3rem 5%;
    }

    #user-panel #home #welcome .wrapper > div:first-child {
        justify-content: center; align-items: center;
        width: 90%;
    }

    #user-panel #home #welcome .wrapper > div:first-child h1 {
        font-size: 1.8rem;
    }

    #user-panel #home #welcome .wrapper > div:first-child h1,
    #user-panel #home #welcome .wrapper > div:first-child p {
        text-align: center;
    }

    #user-panel #home #welcome .wrapper div.image-wrapper {
       display: none;
    }

   
}

